import firebase from 'firebase'
// var firebase = require('firebase/app');
require('firebase/auth');
require('firebase/firestore');
require('firebase/storage');

var app = firebase.initializeApp({
  apiKey: "AIzaSyAVshw1NTYQ6DK0JL2P1qUtvYn4PJ_2rN4",
  authDomain: "tod3d-e5390.firebaseapp.com",
  databaseURL: "https://tod3d-e5390-default-rtdb.firebaseio.com",
  projectId: "tod3d-e5390",
  storageBucket: "tod3d-e5390.appspot.com",
  messagingSenderId: "914433693546",
  appId: "1:914433693546:web:51909a34cff00496a23713",
  measurementId: "G-7R599T0PK5"
});

const appCheck = firebase.appCheck();
appCheck.activate('6LfOlCkkAAAAAOlocWqJm0xVF5JKtXEQteOyUKIk', true);

let db = app.firestore();
let auth = app.auth()
let storage = app.storage()

export { db, auth, storage }