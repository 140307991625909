import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import en from './i18n/vuetify/en'
import es from './i18n/vuetify/es'

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#4b3f92',
        secondary: '#d92180',
        accent: '#f6c75b',
      },
      // dark: {
      //   primary: '#',
      //   secondary: '#d92180',
      //   accent: '#f6c75b',
      // },
    },
  },
  lang: {
    locales: { en, es },
    current: navigator.language.substring(0, 2),
  },
});
