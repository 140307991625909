import { es } from 'vuetify/lib/locale/'

export default {
  ...es,

  key1: "key 1 internationalization",
  key2: "key 2 internationalization",

  name: "Nombre",
  surname: "Apellido",
  phone: "Telefono",
  email: "Email",
  send: "Enviar",
  home: "HOME",
  courses: "CURSOS",
  contact: "CONTACTO",
  aboutUs: "QUIENES SOMOS",
  filters: "Filtros",
  textAreaPlaceholder: "Deja aca tus dudas y consultas",
  needMoreHelp: "Dejanos tus dudas y consultas",
  yourData: "Tus datos",
  contactUsVia: "Contactanos via",

  namespace: {
    key3: "key 3 internationalization"
  }
}