<template>
  <div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'SimpleLayout',

  mounted () {
  }
}
</script>

<style>
</style>