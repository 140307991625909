const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Site/Home.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/Site/About.vue')
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => import('../views/Site/Courses.vue')
  },
  {
    path: '/masters',
    name: 'masters',
    component: () => import('../views/Site/Courses.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Site/Contact.vue')
  },
  {
    path: '/academy',
    name: 'academy',
    component: () => import('../views/Site/Academy.vue')
  },
  {
    path: '/courses/:id',
    name: 'course detail',
    component: () => import('../views/Site/CourseDetail.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'simple'
    },
    component: () => import('../views/Admin/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      layout: 'simple'
    },
    component: () => import('../views/Admin/Dashboard.vue')
  },
  {
    path: '/admin/:id',
    name: 'entityCRUD',
    meta: {
      layout: 'simple'
    },
    component: () => import('../views/Admin/EntityCRUD.vue')
  }
]

export default routes