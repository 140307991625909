import { en } from 'vuetify/lib/locale/'

export default {
  ...en,

  key1: "key 1 internationalization",
  key2: "key 2 internationalization",

  name: "Name",
  surname: "Lastname",
  phone: "Phone",
  email: "Email",
  send: "Send",
  home: "HOME",
  courses: "COURSES",
  contact: "CONTACT",
  aboutUs: "ABOUT US",
  filters: "Filters",
  textAreaPlaceholder: "How can we help you?",
  needMoreHelp: "Need more help?",
  yourData: "Your Information",
  contactUsVia: "Contact us:",

  namespace: {
    key3: "key 3 internationalization"
  }
}