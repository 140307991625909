<template>
  <div>
    <v-app-bar
      :collapse="!collapseOnScroll"
      :collapse-on-scroll="collapseOnScroll"
      color="rgba(255, 0, 0, 0)"
      elevation="0"
      scroll-target="#scrolling-techniques-6"
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <!-- <div
        v-if="$router.currentRoute.name"
        class="text-h4 ml-5"
      >
        {{$router.currentRoute.name.charAt(0).toUpperCase()}}{{$router.currentRoute.name.slice(1)}}
      </div> -->
      <div class="d-flex align-center justify-center">
        <span class="mr-5">
          <v-img class="d-none d-md-flex" max-width="80px" max-height="50px" src="@/assets/images/tod3dlogo.png"/>
        </span>
        <span @click="$router.push('/')" class="pointer menu-option text-caption text-md-subtitle-1 font-weight-medium mr-4" :class="{['menu-active']: $router.history.current.path === '/'}">
          {{ $vuetify.lang.locales[$vuetify.lang.current].home }}
        </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn @click="$router.push('/about')" disabled class="pointer menu-option text-caption text-md-subtitle-1 font-weight-medium mr-4" :class="{['menu-active']: $router.history.current.path === '/about'}">
                {{ $vuetify.lang.locales[$vuetify.lang.current].aboutUs }}
              </v-btn>
            </span>
          </template>
          <span>Proximamente</span>
        </v-tooltip>
        <span @click="$router.push('/courses')" class="pointer menu-option text-caption text-md-subtitle-1 font-weight-medium mr-4" :class="{['menu-active']: $router.history.current.path === '/courses'}">
          {{ $vuetify.lang.locales[$vuetify.lang.current].courses }}
        </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn @click="$router.push('/about')" disabled class="pointer menu-option text-caption text-md-subtitle-1 font-weight-medium mr-4" :class="{['menu-active']: $router.history.current.path === '/about'}">
                MASTER CLINICO
              </v-btn>
            </span>
          </template>
          <span>Proximamente</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn @click="$router.push('/academy')" disabled class="pointer menu-option text-caption text-md-subtitle-1 font-weight-medium mr-4" :class="{['menu-active']: $router.history.current.path === '/about'}">
                ACADEMY
              </v-btn>
            </span>
          </template>
          <span>Proximamente</span>
        </v-tooltip>
        <span @click="$router.push('/contact')" class="pointer menu-option text-caption text-md-subtitle-1 font-weight-medium" :class="{['menu-active']: $router.history.current.path === '/contact'}">
          {{ $vuetify.lang.locales[$vuetify.lang.current].contact }}
        </span>
      </div>

      <v-spacer></v-spacer>

      <v-menu
        bottom
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-translate-variant</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title
              @click="handleClickChangeLanguage('es')"
            >
              <v-btn
                :disabled="$vuetify.lang.current === 'es'"
                small
                text
              >
                Español
              </v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              @click="handleClickChangeLanguage('en')"
            >
              <v-btn
                :disabled="$vuetify.lang.current === 'en'"
                small
                text
              >
                English
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <v-btn
        class="ma-2"
        outlined
        color="white"
        @click="handleLogoutClick"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-app-bar
      class="d-md-none"
      color="rgba(255, 0, 0, 0)"
      flat
      dark
      absolute
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <!-- <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Quienes somos</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Cursos</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Master clinico</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Academy</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Contacto</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
    <div class="tod3d-app-container">
      <slot/>
      <div class="footer-container d-flex justify-space-around align-center py-10">
        <div>
          <div>
            <span class="mr-5">
              <v-img class="d-none d-md-flex image-footer" max-width="80px" max-height="50px" src="@/assets/images/tod3dlogo.png"/>
            </span>
          </div>
          <div class="white--text">
            Home
          </div>
          <div class="white--text">
            Quienes somos
          </div>
          <div class="white--text">
            Cursos
          </div>
          <div class="white--text">
            Master Clinico
          </div>
          <div class="white--text">
            Academy
          </div>
          <div class="white--text">
            Contacto
          </div>
        </div>
        <div>
          <div class="white--text pb-1">
            <v-icon
              left
              dark
            >
              mdi-whatsapp
            </v-icon>
            <span>
              +54 123 45678
            </span>
          </div>
          <div class="white--text pb-1">
            <v-icon
              left
              dark
            >
              mdi-email-outline
            </v-icon>
            <span>
              info@tod3doficial.com.ar
            </span>
          </div>
          <div class="white--text pb-1">
            <v-icon
              left
              dark
            >
              mdi-instagram
            </v-icon>
            <span>
              T.O.D.3D
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <v-footer
      dark
      padless
    >
      <v-card
        class="flex"
        color="rgb(26, 0, 50)"
        flat
        tile
      >
        <v-card-title>
          <strong class="subheading">Encontranos en nuestras redes sociales!</strong>
          <v-spacer></v-spacer>
          <v-btn
            v-for="social in socials"
            :key="social.icon"
            class="mx-4"
            @click="openNewTab(social.link)"
            dark
            icon
          >
            <v-icon size="24px">
              {{ social.icon }}
            </v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
    </v-footer> -->
  </div>
</template>

<script>
import { auth } from '@/firebaseSDK'

export default {
  name: 'MediumLayout',
  data: () => ({
    collapseOnScroll: true,
    drawer: false,
    socials: [
      {
        icon: 'mdi-facebook',
        link: 'https://www.facebook.com/tod3d-104946617999642'
      },
      {
        icon: 'mdi-instagram',
        link: 'https://www.instagram.com/t.o.d3d/'
      }
    ]
  }),

  methods: {
    async handleLogoutClick () {
      await auth.signOut()
      localStorage.clear();
      this.$router.push('/login')
    },

    handleClickChangeLanguage (lang) {
      this.$vuetify.lang.current = lang
    },

    openNewTab (link) {
      window.open(link);
    }
  }
}
</script>

<style lang="scss" scoped>

// .tod3d-app-container {
//   height: calc(100vh - 64px);
// }

.menu-option {
  height: 27px
}

.menu-option:hover {
  background-color: rgba(76, 0, 255, 0.05);
}

.menu-active {
  border-bottom: 2px solid rgba(76, 0, 255, 0.3);
}

.footer-container {
  width: 100%;
  background-color: rgba(79, 81, 80, 1);
}
</style>