<template>
  <v-app>
    <div id="app">
      <SimpleLayout v-if="layout === 'simple'">
        <v-slide-x-transition mode="out-in">
          <router-view class="view-container"/>
        </v-slide-x-transition>
      </SimpleLayout>
      <MediumLayout v-else>
        <v-slide-x-transition mode="out-in">
          <router-view class="view-container"/>
        </v-slide-x-transition>
      </MediumLayout>
    </div>
  </v-app>
</template>

<script>
import MediumLayout from '@/layouts/Medium'
import SimpleLayout from '@/layouts/Simple'
// import routes from '@/router/routes.js' 

export default {
  components: {
    SimpleLayout,
    MediumLayout
  },

  computed: {
    layout() {
      return this.$router.history.current.meta.layout ? this.$router.history.current.meta.layout : 'medium'
    }
  },

  data: () => ({
    // layout: ''
  }),

  mounted () {
    // console.log(routes, 'routes routes ')
    // let selectedRoute = routes.find((route) => route.name === this.$router.currentRoute.name)
    // console.log(this.$router.history.current, selectedRoute, 'routes routes ')
    // this.layout = selectedRoute.meta.layout ? selectedRoute.meta.layout : 'medium'
  }
}
</script>

<style lang="scss">
.pointer {
  cursor: pointer
}

#app {
  min-height: 100%;
  background-color: #fffeff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#header {
  box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
  margin-bottom: 100px;
}

</style>
